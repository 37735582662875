import { tagsInputAnatomy } from '@ark-ui/anatomy';
import { defineSlotRecipe } from '@chakra-ui/react';
import { inputRecipe } from './input';
import { tagSlotRecipe } from './tag';

export const tagsInputSlotRecipe = defineSlotRecipe({
  slots: tagsInputAnatomy.keys(),
  className: 'tagsInput',
  base: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5',
      width: 'full'
    },
    label: {
      fontWeight: 'medium',
      color: 'gray.700',
      marginBottom: '2'
    },
    control: {
      ...inputRecipe.base,
      alignItems: 'center',
      borderColor: 'border.default',
      borderRadius: 'l2',
      borderWidth: '1px',
      display: 'flex',
      flexWrap: 'wrap',
      outline: 0,
      transitionDuration: 'normal',
      transitionProperty: 'border-color, box-shadow',
      transitionTimingFunction: 'default',
      height: '100%',
      paddingInline: 2
    },
    input: {
      background: 'transparent',
      color: 'fg.default',
      outline: 'none',
      mx: 1
    },
    itemPreview: {
      ...tagSlotRecipe.base?.root,
      _highlighted: {
        bgColor: 'blue.100'
      },
      margin: 1
    },
    clearTrigger: {
      marginLeft: '2',
      padding: '2',
      borderRadius: 'md',
      color: 'gray.500',
      _hover: {
        color: 'gray.700'
      }
    }
  },
  variants: {
    size: {
      xs: {
        control: {
          ...inputRecipe.variants?.size?.xs
        }
      },
      sm: {
        control: {
          ...inputRecipe.variants?.size?.sm
        }
      },
      md: {
        control: {
          ...inputRecipe.variants?.size?.md
        }
      },
      lg: {
        control: {
          ...inputRecipe.variants?.size?.lg
        }
      }
    },
    variant: {
      outline: {
        control: {
          ...inputRecipe.variants?.variant?.outline
        }
      },
      filled: {
        control: {
          ...inputRecipe.variants?.variant?.filled
        }
      },
      flushed: {
        control: {
          ...inputRecipe.variants?.variant?.flushed
        }
      }
    }
  },
  defaultVariants: {
    size: 'md',
    variant: 'outline'
  }
});
