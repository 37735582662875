'use client';

import { PLATFORM_LANGUAGES, PlatformLanguageType } from '@company/common/types';
import { Messages } from '@company/i18n';
import { setupI18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import { useState } from 'react';

export function I18nProvider({
  children,
  language,
  messages
}: {
  children: React.ReactNode;
  language: PlatformLanguageType;
  messages: Messages;
}) {
  const [i18n] = useState(() => {
    return setupI18n({
      locale: PLATFORM_LANGUAGES[language].localeCode,
      messages: { [PLATFORM_LANGUAGES[language].localeCode]: messages }
    });
  });
  return <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>;
}
