'use client';

import { updateUserLastVisitedPathnameAction } from '@server/actions/last-page/last-visited-page';
import { usePathname } from 'next/navigation';
import React from 'react';

const excludedPaths = ['/sign-in', '/sign-up', '/forgot-password', '/set-password', '/not-found'];

interface LastPageContextProps {}

const LastPageContext = React.createContext<LastPageContextProps>({});

export const LastPageProvider = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();

  const updateLastVisitedPathname = async () => {
    await updateUserLastVisitedPathnameAction({ pathname });
  };

  React.useEffect(() => {
    if (pathname && !excludedPaths.includes(pathname)) {
      updateLastVisitedPathname();
    }
  }, [pathname]);

  return <LastPageContext.Provider value={{}}>{children}</LastPageContext.Provider>;
};
