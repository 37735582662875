import { comboboxAnatomy } from '@ark-ui/anatomy';
import { defineSlotRecipe } from '@chakra-ui/react';

export const comboboxSlotRecipe = defineSlotRecipe({
  slots: comboboxAnatomy.keys(),
  className: 'combobox',
  base: {
    root: {
      width: '100%'
    },
    label: {
      fontWeight: 'medium',
      color: 'gray.700',
      marginBottom: '2'
    },
    control: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    input: {
      width: '100%',
      minWidth: 0,
      outline: 0,
      position: 'relative',
      appearance: 'none',
      colorPalette: 'gray',
      _disabled: {
        layerStyle: 'disabled'
      },
      '--focus-color': 'colors.border.emphasized',
      '--error-color': 'colors.border.error',
      padding: '2',
      borderRadius: 'md',
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'bg',
      _hover: {
        borderColor: 'gray.300'
      },
      _focusVisible: {
        outline: '1px solid var(--focus-color)',
        borderColor: 'var(--focus-color)'
      },
      _invalid: {
        borderColor: 'var(--error-color)'
      }
    },
    trigger: {
      marginLeft: '2',
      padding: '2',
      borderRadius: 'md',
      backgroundColor: 'gray.100',
      _hover: {
        backgroundColor: 'gray.200'
      }
    },
    clearTrigger: {
      marginLeft: '2',
      padding: '2',
      borderRadius: 'md',
      color: 'gray.500',
      _hover: {
        color: 'gray.700'
      }
    },
    positioner: {
      zIndex: '1'
    },
    content: {
      backgroundColor: 'white',
      borderRadius: 'md',
      boxShadow: 'md',
      overflow: 'hidden'
    },
    itemGroup: {
      padding: '2'
    },
    itemGroupLabel: {
      fontWeight: 'bold',
      color: 'gray.600',
      fontSize: 'sm'
    },
    item: {
      padding: '2',
      cursor: 'pointer',
      _hover: {
        backgroundColor: 'gray.200',
        color: 'gray.700'
      },
      _selected: {
        backgroundColor: 'gray.200',
        color: 'gray.700'
      }
    },
    itemText: {
      flex: '1'
    },
    itemIndicator: {
      color: 'blue.500'
    }
  },
  variants: {
    size: {
      xs: {
        input: {
          padding: '1',
          fontSize: 'xs'
        },
        trigger: {
          padding: '1'
        },
        clearTrigger: {
          padding: '1'
        },
        item: {
          padding: '1'
        }
      },
      sm: {
        input: {
          padding: '2',
          fontSize: 'xs'
        },
        trigger: {
          padding: '2'
        },
        clearTrigger: {
          padding: '2'
        },
        item: {
          padding: '2'
        }
      },
      md: {
        input: {
          padding: '3',
          fontSize: 'sm'
        },
        trigger: {
          padding: '3'
        },
        clearTrigger: {
          padding: '3'
        },
        item: {
          padding: '3'
        }
      },
      lg: {
        input: {
          padding: '4',
          fontSize: 'md'
        },
        trigger: {
          padding: '4'
        },
        clearTrigger: {
          padding: '4'
        },
        item: {
          padding: '4'
        }
      }
    }
  },
  defaultVariants: {}
});
