import { createSystem, defaultConfig, defineConfig, mergeConfigs } from '@chakra-ui/react';
import { comboboxSlotRecipe, tagsInputSlotRecipe } from './recipies';

const newConfig = defineConfig({
  theme: {
    slotRecipes: {
      combobox: comboboxSlotRecipe,
      tagsInput: tagsInputSlotRecipe
    },
    tokens: {
      colors: {
        primary: {
          50: { value: '#F5FBFF' },
          100: { value: '#EDF7FF' },
          200: { value: '#D1E8FF' },
          300: { value: '#B5D5FF' },
          400: { value: '#7CA4FC' },
          500: { value: '#4469fc' },
          600: { value: '#3656E3' },
          700: { value: '#263FBD' },
          800: { value: '#182D96' },
          900: { value: '#0E1D73' },
          950: { value: '#060F4A' },
          solid: { value: '#4469fc' },
          fg: { value: '#4469fc' },
          emphasized: { value: '#3656E3' },
          muted: { value: '#D1E8FF' },
          subtle: { value: '#F5FBFF' }
        }
      }
    },
    semanticTokens: {
      colors: {
        Background: {
          value: '#fafafa'
        },

        Sidebar: {
          value: '#ffffff'
        }
      },
      shadows: {
        xs: {
          value: 'rgba(50, 50, 105, 0.08) 0px 2px 4px 0px, rgba(0, 0, 0, 0.04) 0px 1px 1px 0px'
        },
        sm: {
          value: 'rgba(50, 50, 105, 0.12) 0px 4px 6px 0px, rgba(0, 0, 0, 0.06) 0px 2px 2px 0px'
        },
        md: {
          value: 'rgba(50, 50, 105, 0.15) 0px 6px 8px 0px, rgba(0, 0, 0, 0.08) 0px 3px 3px 0px'
        },
        lg: {
          value: 'rgba(50, 50, 105, 0.18) 0px 8px 10px 0px, rgba(0, 0, 0, 0.10) 0px 4px 4px 0px'
        }
      }
    }
  }
});

const config = mergeConfigs(defaultConfig, newConfig);
const system = createSystem(config);

export { system };
