'use client';

import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeProvider } from '../components/ui/color-mode';
import { system } from '../style-system';
import { EmotionCacheProvider } from './emotion-cache';

export function DesignThemeProvider(props: { children: React.ReactNode }) {
  return (
    <EmotionCacheProvider>
      <ChakraProvider value={system}>
        <ColorModeProvider defaultTheme="light">{props.children}</ColorModeProvider>
      </ChakraProvider>
    </EmotionCacheProvider>
  );
}
