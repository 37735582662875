'use client';

import React, { useEffect, useState } from 'react';

interface SidebarProviderProps {
  children: React.ReactNode;
  defaultIsHidden: boolean;
  defaultIsExpanded: boolean;
}

interface SidebarContextProps {
  expand: () => void;
  collapse: () => void;
  isExpanded: boolean;
  isCollapsed: boolean;
  isHidden: boolean;
}

const SidebarContext = React.createContext<SidebarContextProps>({
  isExpanded: false,
  expand: () => {},
  collapse: () => {},
  isCollapsed: false,
  isHidden: false
});

export const useSidebar = () => React.useContext(SidebarContext);

export const SidebarProvider = ({
  children,
  defaultIsHidden,
  defaultIsExpanded
}: SidebarProviderProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded || false);
  const [isHidden, setIsHidden] = useState<boolean>(defaultIsHidden || false);
  const isCollapsed = !isExpanded;

  const expand = () => {
    setIsExpanded(true);
  };

  const collapse = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    setIsExpanded(defaultIsExpanded);
    setIsHidden(defaultIsHidden);
  }, [defaultIsHidden, defaultIsExpanded]);

  const value = React.useMemo(
    () => ({
      isExpanded,
      expand,
      collapse,
      isCollapsed,
      isHidden
    }),
    [isExpanded, isCollapsed, isHidden]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
